var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page userDetailsPage" }, [
    _c("div", { staticClass: "narrowBox" }, [
      _c(
        "div",
        { staticClass: "left-menu" },
        [
          _c("userInfoCard", {
            attrs: { userinfo: _vm.data.baseInfo },
            on: {
              fource: _vm.fourceHandler,
              errorRefsh: function($event) {
                return _vm.loadUserFunc()
              }
            }
          }),
          _c(
            "ul",
            { staticClass: "menu" },
            _vm._l(_vm.menu, function(nav, index) {
              return _c(
                "li",
                {
                  key: index,
                  class: { active: _vm.activeNum === index },
                  on: {
                    click: function($event) {
                      return _vm.handlerMenuClick(index)
                    }
                  }
                },
                [
                  _c("i", { class: nav.icon }),
                  nav.num !== undefined
                    ? _c("span", [_vm._v(_vm._s(nav.num))])
                    : _vm._e()
                ]
              )
            }),
            0
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right-content" },
        [
          _vm.activeNum === 0
            ? _c("Services", {
                attrs: { data: _vm.servicesData, "active-num": 0 },
                on: { delSuccess: _vm.delSuccessHandler }
              })
            : _vm._e(),
          _vm.activeNum === 1
            ? _c("CompanyGroup", {
                attrs: {
                  data: _vm.groups,
                  type: "group",
                  "active-num": 1,
                  page: _vm.page
                },
                on: { delSuccess: _vm.delSuccessHandler }
              })
            : _vm._e(),
          _vm.activeNum === 2
            ? _c("CompanyGroup", {
                attrs: {
                  data: _vm.enters,
                  type: "company",
                  "active-num": 2,
                  page: _vm.page
                },
                on: { delSuccess: _vm.delSuccessHandler }
              })
            : _vm._e(),
          _vm.activeNum === 3
            ? _c("BaseInfo", {
                attrs: {
                  data: _vm.data,
                  "active-num": 3,
                  fources: _vm.foruces
                },
                on: { change: _vm.baseinfoChangeHandler }
              })
            : _vm._e(),
          _vm.activeNum !== 3
            ? _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "prev, pager, next",
                  "page-size": _vm.pageSize,
                  "hide-on-single-page": true,
                  total: _vm.total,
                  "current-page": _vm.page
                },
                on: { "current-change": _vm.pageChange }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }